<template>
    <v-container fluid>
        <v-btn icon @click="exportToCsv">
            <v-icon v-if="!excel" color="green" large> mdi-file-excel </v-icon>
            <v-progress-circular
                v-else
                :size="30"
                color="green"
                indeterminate
            ></v-progress-circular>
        </v-btn>
        <v-btn icon @click="exportToPDF">
            <v-icon v-if="!pdf" color="red" large> mdi-file-pdf-box </v-icon>
            <v-progress-circular
                v-else
                :size="30"
                color="red"
                indeterminate
            ></v-progress-circular>
        </v-btn>
        <v-expansion-panels
            v-model="panel"
        >
            <v-expansion-panel>
                <v-expansion-panel-header class="card-title mt-1">
                    <v-row align="center">
                        <v-col cols="6">
                            Έξοδα
                            <v-icon color="black" class="px-4" @click.stop="togglePanel()">
                                mdi-tune
                            </v-icon>
                        </v-col>
                        <v-col cols="6" align="end">
                            <v-btn class="my-2 mr-2" dark small color="black" @click="createExpense">
                                <v-icon dark>mdi-plus</v-icon>Καταχώριση
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <filters
                        :filters="displayInfo"
                        @reloadFilters="reloadFilters"
                        @filterResults="filterResults"
                    />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <array-view
            :items="expenses"
            :headers="displayInfo.filter(element => element.showArray)"
            :total-pages="totalPages"
            :page="page"
            :size="size"
            :totals="totals"
            @onDelete="onDelete"
            @handleClick="handleClick"
            @paginationChange="paginationChange"
        />
        <div id="export" />
    </v-container>
</template>

<script>
    import expenseService from '@/services/expense'
    import ArrayView from '@/components/ArrayView'
    import Filters from '@/components/Filters'
    import categoryService from '@/services/category'
    import chargeUnitService from '@/services/chargeUnit'
    import html2pdf from 'html2pdf.js'

    export default {
        name: 'Expense',
        components: { ArrayView, Filters },
        data: () => {
            return {
                successfullImport: null,
                expenses: [],
                displayInfo: [
                    {
                        text: 'Id',
                        value: 'id',
                        showArray: true,
                        disabled: false
                    },
                    {
                        text: 'Ημερομηνία Εξόδου',
                        value: 'expenseDate',
                        searchable: true,
                        type: 'date',
                        meta: 'from',
                        showArray: true,
                        disabled: false
                    },
                    {
                        text: 'Ημερομηνία Εξόδου',
                        value: 'expenseDate',
                        searchable: true,
                        showArray: false,
                        type: 'date',
                        meta: 'to',
                        disabled: false
                    },
                    {
                        text: 'Τίτλος',
                        value: 'title',
                        searchable: true,
                        type: 'simple',
                        showArray: true,
                        disabled: false
                    },
                    {
                        text: 'Περιγραφή',
                        value: 'description',
                        searchable: true,
                        type: 'simple',
                        showArray: true,
                        disabled: false
                    },
                    {
                        text: 'Κατηγορία',
                        value: 'category',
                        subLevel: 'title',
                        type: 'api',
                        level: 1,
                        data: null,
                        searchable: true,
                        showArray: true,
                        disabled: false
                    },
                    {
                        text: 'Αριθμός τιμολογίου',
                        value: 'expenseInfo',
                        subLevel: 'invoiceNumber',
                        type: 'simple',
                        level: 1,
                        searchable: true,
                        showArray: false,
                        single: true,
                        disabled: false
                    },
                    {
                        text: 'Ημερομηνία λήξης επιταγής',
                        value: 'expenseInfo',
                        subLevel: 'checkExpirationDate',
                        type: 'checkDate',
                        meta: 'from',
                        level: 1,
                        searchable: false,
                        showArray: false,
                        disabled: false,
                        single: true
                    },
                    {
                        text: 'Ημερομηνία λήξης επιταγής',
                        value: 'expenseInfo',
                        subLevel: 'checkExpirationDate',
                        type: 'checkDate',
                        meta: 'to',
                        level: 1,
                        searchable: false,
                        showArray: false,
                        disabled: false,
                        single: true
                    },
                    {
                        text: 'Μέθοδος πληρωμής',
                        value: 'paymentMethod',
                        data: [
                            { displayValue: 'ΜΕΤΡΗΤΑ', value: 'CASH' },
                            { displayValue: 'ΕΠΙΤΑΓΗ', value: 'CHECK' },
                            { displayValue: 'ΤΡΑΠΕΖΑ', value: 'BANK' },
                            { displayValue: 'ΚΟΥΠΟΝΙΑ', value: 'COUPONS' }
                        ],
                        type: 'select',
                        searchable: true,
                        showArray: true,
                        disabled: false
                    },
                    {
                        text: 'Κατάσταση',
                        value: 'status',
                        data: [
                            { displayValue: 'ΕΚΚΡΕΜΕΙ', value: 'PENDING' },
                            { displayValue: 'ΠΛΗΡΩΜΕΝΟ', value: 'PAID' },
                            { displayValue: 'ΑΚΥΡΩΜΕΝΟ', value: 'CANCELED' }
                        ],
                        type: 'select',
                        searchable: true,
                        showArray: true,
                        disabled: false
                    },
                    {
                        text: 'Σύνολο (€)',
                        value: 'amount',
                        searchable: true,
                        type: 'number',
                        showArray: true,
                        disabled: false
                    },
                    {
                        text: 'Μονάδα χρέωσης',
                        value: 'chargeUnit',
                        subLevel: 'title',
                        type: 'api',
                        level: 1,
                        data: null,
                        searchable: true,
                        showArray: true,
                        disabled: false
                    },
                    {
                        text: 'Αριθμός επιταγής',
                        value: 'expenseInfo',
                        subLevel: 'checkNumber',
                        type: 'simple',
                        level: 1,
                        searchable: true,
                        showArray: false,
                        disabled: false,
                        single: true
                    },
                    {
                        text: 'Καταχωρήθηκε από',
                        value: 'user',
                        subLevel: 'username',
                        searchable: true,
                        type: 'simple',
                        showArray: true,
                        disabled: false
                    },
                    {
                        text: 'Ημερομηνία Καταχώρησης',
                        value: 'createdAt',
                        searchable: true,
                        type: 'createdAtDate',
                        meta: 'from',
                        showArray: true,
                        disabled: false
                    },
                    {
                        text: 'Ημερομηνία Καταχώρησης',
                        value: 'createdAt',
                        searchable: true,
                        type: 'createdAtDate',
                        meta: 'to',
                        showArray: false,
                        disabled: false
                    }
                ],
                showImportDialog: false,
                size: 5,
                totalPages: 0,
                page: 1,
                panel: undefined,
                query: {},
                totals: { total: 0, totalAfterTaxes: 0, finalTotal: 0 },
                sort: null,
                pdf: false,
                excel: false
            }
        },
        watch: {
            panel() {
                if (this.panel === 0 && this.displayInfo[5].data === null && this.displayInfo[12].data === null) {
                    this.fetchAllCategories()
                    this.fetchAllChargeUnits()
                }
            },
            page() {
                var path = `/expenses?page=${this.page}&size=${this.size}`
                if (this.$route.fullPath !== path) {
                    this.$router.push(path)
                }
            },
            size() {
                var path = `/expenses?page=${this.page}&size=${this.size}`
                if (this.$route.fullPath !== path) {
                    this.$router.push(path)
                }
            }
        },
        created() {
            this.page = this.$route.query.page ? parseInt(this.$route.query.page) : this.page
            this.size = this.$route.query.size ? parseInt(this.$route.query.size) : this.size
            console.log(this.$router)
            if (this.$store.getters.getFilters) {
                this.displayInfo = this.$store.getters.getFilters
            }
            if (this.$store.getters.getQuery) {
                this.query = this.$store.getters.getQuery
            }
            this.fetchAllExpenses()
            this.fetchExpensesTotals()
        },
        methods: {
            exportToCsv() {
                this.excel = true
                expenseService.getExpensesCsv(this.query, this.sort).then((resp) => {
                    const data = 'text;charset=utf-8,' + encodeURIComponent(resp.data)
                    const link = document.createElement('a')
                    link.href = 'data:' + data
                    link.download = `export.csv`

                    const container = document.getElementById('export')
                    container.appendChild(link)
                    link.click()
                    this.excel = false
                })
            },
            exportToPDF() {
                this.pdf = true
                html2pdf(document.getElementById('element-to-convert'),
                         {
                             hotfixes: 'px_scaling',
                             margin: 1,
                             filename: 'expenses.pdf',
                             image: { type: 'jpeg', quality: 1 },
                             html2canvas: { scale: 2 },
                             jsPDF: { unit: 'px', orientation: 'landscape', format: [1500, 1080], putOnlyUsedFonts: true }
                         }
                )
                this.pdf = false
            },
            filterResults(query, sort) {
                this.query = query
                this.sort = sort
                this.page = 1
                this.fetchAllExpenses()
                this.fetchExpensesTotals()
            },
            reloadFilters() {
                this.fetchAllCategories()
                this.fetchAllChargeUnits()
                this.query = {}
                this.sort = null
                this.totals = { total: 0, totalAfterTaxes: 0, finalTotal: 0 }
                this.fetchAllExpenses()
            },
            togglePanel() {
                const curr = this.panel
                this.panel = curr === undefined ? 0 : undefined
            },
            onDelete(expense) {
                expenseService.deleteExpense(expense.id).then(() => this.fetchAllExpenses())
            },
            paginationChange(page, size) {
                this.size = size
                this.page = page
                this.fetchAllExpenses()
            },
            handleClick(action, item) {
                this.$router.push(`/expenses/${item.id}/${action}?page=${this.page}&size=${this.size}`)
            },
            createExpense() {
                this.$router.push(`/expenses/create?page=${this.page}&size=${this.size}`)
            },
            fetchAllExpenses() {
                expenseService.getExpenses(this.page, this.size, this.query, this.sort).then(resp => {
                    this.expenses = resp.data.content
                    this.totalPages = resp.data.totalPages
                })
            },
            fetchAllCategories() {
                categoryService.getAllCategories().then(resp => {
                    this.displayInfo[5].data = resp.data
                })
            },
            fetchAllChargeUnits() {
                chargeUnitService.getAllChargeUnits().then(resp => {
                    this.displayInfo[12].data = resp.data
                })
            },
            fetchExpensesTotals() {
                expenseService.getTotals(this.query).then(resp => {
                    this.totals = resp.data
                })
            }
        }
    }
</script>

<style>
.v-expansion-panel-header__icon {
    display: none !important;
}
</style>

<style scoped>

.card-title {
    font-weight: 500;
    font-size: 1.25rem;
}
.v-list-item {
    min-height: 30px !important;
}

.v-card {
    min-width: 250px;
    position: relative;
}

.header-buttons {
    align-self: center;
}

.edit-button:hover {
    background-color: whitesmoke;
    color: white;
}

.screen-card {
    padding: 4%;
}

.overflow-text {
    max-width: 79%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.pagination {
    justify-content: center;
}
</style>
